.card {
    margin: 40px;
    max-width: 500px;
    border: 5px solid gray;
    padding: 10px;
    border-radius: 40px;
}

.App {
    margin: 10px;
    max-width: 800px;
    border: 5px solid gray;
    padding: 30px;
    border-radius: 30px;
}